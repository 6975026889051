import Coverpage from "./components/Coverpage.jsx";

function App() {
  return (
    <div> 
      <Coverpage />
    </div>
  );
}

export default App;
